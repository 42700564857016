.article-page {
  background-color: #202124;
  padding: 40px 0; 
  color: white; 
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
}

.article-page-mobile {
  background-color: #202124;
  padding: 40px 0; 
  color: white; 
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
}

.article-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px; 
  text-align: left; 
  padding-left: 5%; 
  width: 90%; 
  color: #f1f1f1; 
}

.article-page-mobile h1 {
  font-size: 2.3rem;
  margin-bottom: 20px; 
  text-align: left; 
  padding-left: 5%; 
  width: 95%; 
  color: #f1f1f1; 
}

.article-page .article-content {
  width: 75%; 
  /* max-width: 900px;  */
  border-radius: 10px; 
  padding: 20px; 
  margin-top: 20px; 
}

.article-page-mobile .article-content {
  width: 95%; 
  /* max-width: 900px;  */
  border-radius: 10px; 
  padding: 20px; 
  margin-top: 20px; 
}


.article-page p {
  font-size: 1.0rem;
  line-height: 2;
  color: white; 
  margin-bottom: 50px; 
}

.article-page-mobile p {
  font-size: 1.0rem;
  line-height: 2;
  color: white; 
  margin-bottom: 50px; 
}


.article-page h2, .article-page h3, .article-page h4 {
  font-size: 1.5rem; 
  color: #f1f1f1; 
  margin-top: 20px; 
}


.article-page a {
  color: #007bff; 
  text-decoration: none;
}

.article-page a:hover {
  text-decoration: underline; 
}
