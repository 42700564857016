/* General Marquee Styles */
.marquee {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent overflow */
}

.marquee p {
  font-size: 18px;
  color: white;
  margin-right: 20px;
}

/* Client Logos Container */
.client-logos {
  display: flex;
  align-items: center;
  white-space: nowrap; /* Ensure logos are on a single line */
  animation: marquee 20s linear infinite; /* Animation for scrolling */
}

.client-logo {
  width: 110px;
  margin: 0 20px;
  transition: transform 0.5s ease-in-out;
}

/* Duplicate the logos to create a seamless loop */
.client-logos::after {
  content: "";
  display: flex;
  flex-shrink: 0;
  gap: 20px; /* Match the margin between logos */
}

.client-logos img {
  flex-shrink: 0; /* Prevent logos from shrinking */
}

@keyframes marquee {
  0% {
    transform: translateX(0); /* Start at the initial position */
  }
  100% {
    transform: translateX(-50%); /* Move exactly half the width of the container */
  }
}

/* Marquee on mobile devices */
@media (max-width: 768px) {
  .marquee {
    width: 100%;
  }

  .client-logos {
    animation: marqueeMobile 15s linear infinite; /* Adjust speed for mobile */
  }

  .client-logo {
    width: 120px; /* Adjust logo size for mobile */
    margin: 0 40px;
  }

  /* Adjust marquee animation for mobile */
  @keyframes marqueeMobile {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%); /* Move exactly half the width of the container */
    }
  }
}
