body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }
  
  .relative {
    position: relative;
  }

  .desktop-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem; /* Space between text and image */
    text-align: center; /* Center text next to the phone */
  }
  
  .desktop-layout .text-white {
    width: 100%; /* Adjust text width as needed */
    text-align: center; /* Ensure text is centered */
  }
  
  .desktop-layout img {
    width: 100%; /* Adjust image width as needed */
  }
  
  .min-h-screen {
    min-height: 100vh;
  }
  
  .bg-blue-500 {
    background-color: #1B202B;
    /* background-color: #3b82f6; */
  }
  
  .bg-white-500 {
    background-color: white;
    color: white;
  }
  
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .items-center {
    align-items: center;
  }
  
  .items-start {
    align-items: flex-start;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .justify-start {
    justify-content: flex-start;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  
  .bg-opacity-70 {
    background-color: rgba(59, 130, 246, 0.7);
  }
  
  .text-white {
    color: white;
  }
  
  .text-blue-500 {
    color: #3b82f6;
  }
  
  .text-3xl {
    font-size: 1.875rem;
  }
  
  .text-5xl {
    font-size: 3rem;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .mt-16 {
    margin-top: 4rem; /* Added margin-top for spacing */
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .p-8 {
    padding: 2rem;
  }
  
  .rounded-md {
    border-radius: 0.375rem;
  }
  
  .w-full {
    width: 95%;
  }
  
  .w-1\/3 {
    width: 33.33%; /* Set description width to 1/3 */
  }
  
  .w-2\/3 {
    width: 66.67%; /* Set image width to 2/3 */
  }
  
  .store-button {
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-weight: 600;
    border: 2px solid white;
    background-color: transparent;
    color: white;
  }
  
  .store-button:hover {
    background-color: white;
    color: #3b82f6;
    transition: 0.3s ease-in-out;
  }
  
  .absolute {
    position: absolute;
  }
  
  .inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .opacity-50 {
    opacity: 0.1;
  }
  
  .bg-cover {
    background-size: cover;
  }
  
  .bg-center {
    background-position: center;
  }
  
  .gap-16 {
    gap: 4rem; /* For spacing between description and image */
  }
  
  .text-center {
    text-align: center;
  }
  