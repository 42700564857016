/* Fullscreen Container */
.hero-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: #202124;
  color: white;
  width: 100%;
  position: relative;
}

/* Top Bar */
.top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  background-color: transparent;
  position: absolute;
  top: 0;
  align-items: center;
}

.top-bar a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin: 0 15px;
}

.top-bar-left {
  flex-grow: 1;
  text-align: left;
}

.top-bar-right {
  flex-grow: 1;
  text-align: right;
}

.top-bar-center {
  flex-grow: 0;
  display: flex;
  justify-content: center;
}

.top-bar-image {
  max-height: 50px;
  object-fit: contain;
}

/* Centered Logo and Search Bar */
.center-content {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  margin-top: 30vh;
}

.logo-image {
  width: 250px;
  margin-bottom: 20px;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  position: relative;
}

.search-input {
  padding: 12px;
  font-size: 18px;
  border: none;
  border-radius: 25px;
  width: 100%;
  transition: border-radius 0.3s ease; /* Smooth transition for border radius */
}

.search-input.open-dropdown {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* Dropdown Styling */
.dropdown {
  position: absolute;
  top: 50px;
  width: calc(100%);
  background-color: #171717;
  color: white;
  list-style-type: none;
  border-width: 3px;
  border-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 10;
}

.dropdown li {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #333;
}

/* Response Text */
.response-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 300;
  color: white;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

/* Fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.store-button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

/* Portfolio Section */
.portfolio-section {
  display: flex;
  justify-self: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 100vh;
  width: 95%;
  position: relative;
  padding: 10px;
}

.portfolio-container {
  display: flex;
  width: 100%;
  height: 80vh;
  border-radius: 8px;
  overflow: hidden; /* Hide anything outside the rounded corners */
  background-color: #333;
}

.portfolio-background {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #121212;
}

.portfolio-image {
  width: 40%; /* Adjust this to control the image size */
  background-size: cover;
  background-position: flex-start;
  border-radius: 8px;
  height: 90%;
  width: 100%;
  margin-left: 10%;
}

.portfolio-text {
  height: 100%;
  width: 50%;
  padding: 20px;
  color: white;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.portfolio-text h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.portfolio-text p {
  font-size: 16px;
  font-weight: 300;
  color: #ddd;
  line-height: 1.5;
}

.portfolio-button {
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.portfolio-button:hover {
  background-color: #555;
}

/* Portfolio Navigation */
.portfolio-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.arrow-button {
  padding: 10px 20px;
  width: 45%;
  font-size: 16px;
  cursor: pointer;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-align: center;
}

.arrow-button:hover {
  background-color: #555;
}

.arrow-button:focus {
  outline: none;
}

/* Close Portfolio Button */
.close-portfolio-button {
  margin-top: 50px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 100%;
  text-align: center;
}

.close-portfolio-button:hover {
  background-color: #c0392b;
}

.close-portfolio-button:focus {
  outline: none;
}

/* Marquee Section */
.marquee-section {
  background-color: #171717;
  width: 100%;
  height: 8%;
  padding: 10px 0;
  display: flex;
  /* align-items: center; */
  position: absolute;
  bottom: 0;
}

/* Marquee Styling for Mobile */
@media (max-width: 768px) {
  /* Portfolio Section */
  .portfolio-section {
    display: flex;
    justify-self: center;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding: 0 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 90vh; /* Allow it to scroll vertically */
  }
  
  .portfolio-container {
    display: block; /* Stack vertically on mobile */
    width: 100%;
    height: auto; /* Let content define height */
    background-color: #333;
    border-radius: 8px;
    overflow: visible;
  }
  
  .portfolio-background {
    display: block; /* Stack content vertically */
    padding: 20px;
  }
  
  .portfolio-image {
    width: 100%; 
    max-width: 500px;
    height: auto; 
    background-size: contain;
    background-position: center;
    border-radius: 8px;
    margin: 0 auto;
  }
  
  .portfolio-text {
    width: 100%; /* Take full width */
    padding: 10px;
    text-align: center;
    color: white;
    font-size: 14px; /* Adjust font size */
  }
  
  .portfolio-text h2 {
    font-size: 18px; /* Adjust title size for mobile */
    margin-bottom: 10px; /* Space below title */
  }
  
  .portfolio-text p {
    font-size: 14px; /* Adjust description font size for mobile */
    line-height: 1.5;
    margin-bottom: 20px; /* Space between text and buttons */
  }
  
  .portfolio-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    padding: 0 10px;
  }
  
  .arrow-button {
    font-size: 14px; /* Smaller button size on mobile */
    padding: 8px 16px;
    width: 45%;
    background-color: #333;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .arrow-button:hover {
    background-color: #555;
  }
  
  .close-portfolio-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    width: 100%;
    text-align: center;
  }
  
  .close-portfolio-button:hover {
    background-color: #c0392b;
  }

  /* Close Portfolio Button */
  .close-portfolio-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    width: 100%;
    text-align: center;
  }

  .close-portfolio-button:hover {
    background-color: #c0392b;
  }

  .close-portfolio-button:focus {
    outline: none;
  }
}

