.marquee {
  /* This is literally what I have in the Marquee.js file but this doesn't work? */
  /* Fuck React */
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 0 20px;
}

.marquee-text {
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
}

.client-logo {
  width: 110px;
  height: auto;
  margin: 0 15px;
  flex-shrink: 0;
}
