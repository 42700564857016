* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.pricing,
.sign-up {
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* Start About Us css */
.about-us {
  background: #2a3142;
  /* background: linear-gradient(to bottom, rgb(46, 46, 55), rgb(66, 66, 77)); */
  color: #e2e2e2;
  padding: 4rem;
}

.header {
  text-align: center;
}

.header h1 {
  font-size: 36px;
  font-weight: 600;
}

.body {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 2rem;
}

.content {
  font-size: 18px;
  line-height: 1.6;
}

.content p {
  margin-bottom: 1.5rem;
}

.cta {
  text-align: center;
  margin-top: 2rem;
}

.cta button {
  background-color: #007bff; /* Blue color for the button */
  color: #fff; /* White text color */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor on hover */
}

.cta button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}

/* End About Us css */

/* Articles.css */
.articles {
  background-color: #262430;
  padding: 20px;
}

.articles-title {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.articles-header {
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: start;
}

.articles-h2 {
  color: gold;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: start;
}

.articles-body {
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  align-items: center;
  line-height:32px;
}

.articles-content {
  max-width: 800px;
  text-align: start;
  margin: 0 auto;
  padding-top: 2rem;
}

.articles-paragraph {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* End Articles Content */

/* Header.css */
.header {
  text-align: center;
  margin-bottom: 20px;
}

/* Content.css */
.content {
  margin-bottom: 30px;
}


.services {
  /* Services Text */
  background-repeat: no-repeat;
  background-color: rgb(20, 20, 26);
  color: #ffffff;
  font-size: 34px;
  font-weight: 500;
  text-align: flex-start;
  justify-content: flex-start;
  padding-left: 20px;
  left: 10;
}

.pricing {
  background-image: url('../public/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 50px;
}

.sign-up {
  background-image: url('../public/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 50px;
}