/* Mobile Styling for Article Menu Page */
.article-menu-page {
    background-color: #202124;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px; /* Reduced padding for mobile */
    color: white;
    min-height: 100vh;
  }
  
  .article-menu-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .article-menu-item {
    font-size: 1rem; /* Slightly smaller font size */
    background-color: #333;
    margin: 10px 0;
    padding: 15px;
    text-align: center;
    width: 80%;  /* Adjust width to 80% of screen */
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Remove underline and ensure white text for article links on mobile */
  .article-menu-item a {
    text-decoration: none; /* Remove underline */
    color: white; /* White text for the article title */
    font-weight: 600; /* Slightly bold text */
  }
  
  .article-menu-item:hover {
    background-color: #007bff;
  }
  
  /* Title Adjustments for Mobile */
  .article-menu-title {
    font-size: 2.2rem; /* Slightly smaller title */
    margin-bottom: 20px;
    text-align: left;  /* Title will be aligned to the left */
    width: 90%;
    color: #f1f1f1;
  }
  