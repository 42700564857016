/* Web styling */

/* Set background color to #202124 */
.article-menu-page {
  background-color: #202124;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 0; /* Add padding on top and bottom */
  color: white; /* Text color for better contrast */
  min-height: 100vh; /* Ensure the page takes full height */
}

/* Style the title of the page */
.article-menu-title {
  font-size: 2.5rem;
  margin-bottom: 20px; /* Space below the title */
  text-align: left; /* Align title slightly to the left */
  width: 90%;
  color: #f1f1f1; /* Light color for the heading */
}

/* Style the article menu container */
.article-menu-container {
  width: 80%; /* Set the width to 80% */
  max-width: 900px; /* Maximum width for large screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Add space between items */
}

/* Style each article menu item */
.article-menu-item {
  font-size: 1.5rem;
  background-color: #333; /* Dark background for each item */
  padding: 15px;
  border-radius: 10px;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
  width: 100%; /* Full width of the container */
  text-align: center; /* Align text to center */
}

/* Remove underline and ensure white text for article links */
.article-menu-item a {
  text-decoration: none; /* Remove underline */
  color: white; /* White text for the article title */
  font-weight: 500; /* Slightly bold text */
}

/* Hover effect for article menu item */
.article-menu-item:hover {
  background-color: #007bff; /* Highlight color on hover */
  transform: translateY(-5px); /* Slight lift on hover */
}

/* Add space between consecutive menu items */
.article-menu-item + .article-menu-item {
  margin-top: 15px; /* Space between items */
}

/* Home Button styling */
.home-button-container {
  position: absolute;
  top: 20px; /* Distance from the top */
  right: 20px; /* Distance from the right */
}

.home-button {
  background-color: #007bff; /* Blue background for the button */
  color: white; /* White text color */
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none; /* Remove underline */
  display: inline-block;
  transition: background-color 0.3s;
}

.home-button:hover {
  background-color: #0056b3; /* Darker blue when hovering */
}