.mobile-layout {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.mobile-layout .text-white {
  width: 90%; /* Adjust as needed for mobile */
}

.mobile-layout img {
  margin-top: 20px;
  width: 80%; /* Adjust image size for mobile */
}

.mobile .store-button {
  margin: 10px auto;
  display: block;
}
